<template>
  <figure
    class="image is-inline-block"
    :class="canvasSize"
  >
    <img
      src="@/assets/images/spacer1_1.gif"
      class="is-rounded has-shadow has-background-grey-light"
      :style="{backgroundImage: `url(${$options.filters.getProfileImage(image, id, size)})`}"
    >
  </figure>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      default: 0
    },
    image: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 0
    },
    canvasSize: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  max-height: unset;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>